/* Custom CSS for the SweetAlert2 dialog */
.custom-popup-container {
    max-width: 400px; /* Adjust the max-width as needed */
}

.custom-popup-content {
    padding: 16px;
    /* Add other custom styles for the content here */
}

.custom-popup-actions {
    justify-content: center;
    /* Add other custom styles for the actions here */
}
